import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Navigate } from 'react-router-dom';
import { Button, Checkbox, Flex, Text } from '@fluentui/react-northstar';

import MainLayout from 'components/Layout/MainLayout';
import Spinner from 'components/Spinner';
import { APP_ROUTES } from 'routes/constants';

import { STATE_ROUTES, STATE_STATUSES } from '../../constants/stateRoutes';

import { useStartConfigurationQuery, useTenantQuery } from './module/services';

import messages from './messages';

const CompanyDetails = () => {
    const { isLoading, data } = useTenantQuery();
    const mutation = useStartConfigurationQuery();
    const [isConfirmed, setConfirm] = useState<boolean | undefined>(false);

    if (data?.status && data?.status !== STATE_STATUSES.NOT_CONFIGURED) {
        return <Navigate to={STATE_ROUTES[data.status]} />;
    }
    if (mutation.data?.status && mutation.data?.status !== STATE_STATUSES.NOT_CONFIGURED) {
        return <Navigate to={STATE_ROUTES[mutation.data.status]} />;
    }

    return (
        <MainLayout maxWidth={'550px'}>
            <Spinner vAlign={'center'} className="fs-unmask" isLoading={isLoading} hAlign="center">
                <Text className="fs-unmask" as="h2" weight="bold" styles={{ marginBottom: '5px' }}>
                    <FormattedMessage {...messages.companyDetailsHeading} />
                </Text>
                <Text className="fs-unmask">
                    <FormattedMessage {...messages.companyDetailsDescription} />
                </Text>
                <Flex className="fs-unmask" column={true} styles={{ margin: '25px 0' }}>
                    <Text>
                        <FormattedMessage {...messages.companyDetailsCompanyName} />
                    </Text>
                    <Text
                        className="fs-mask"
                        weight="bold"
                        styles={{ marginTop: '2px', color: '#616161' }}
                    >
                        {data?.companyName}
                    </Text>
                </Flex>
                <Flex className="fs-unmask" styles={{ marginBottom: '25px' }}>
                    <Checkbox
                        label={<FormattedMessage {...messages.companyDetailsCompanyConfirm} />}
                        onChange={(e, data) => setConfirm(data?.checked)}
                    />
                </Flex>
                <Flex className="fs-unmask" hAlign="end" styles={{ marginBottom: '25px' }}>
                    <Button
                        id="company-details-btn"
                        disabled={!isConfirmed}
                        loading={mutation.isLoading}
                        styles={{ width: '140px' }}
                        content={<FormattedMessage {...messages.continue} />}
                        onClick={(e) => {
                            e.preventDefault();
                            mutation.mutate();
                        }}
                        primary
                    />
                </Flex>

                <Flex className="fs-unmask" column={true}>
                    <Text weight="bold" styles={{ marginBottom: '2px' }}>
                        <FormattedMessage {...messages.companyDetailsCorrectDetailsQuestion} />
                    </Text>
                    <Link to={APP_ROUTES.INCORRECT_COMPANY}>
                        <FormattedMessage {...messages.companyDetailsDetailsLink} />
                    </Link>
                </Flex>
            </Spinner>
        </MainLayout>
    );
};

export default CompanyDetails;
