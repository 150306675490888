import React from 'react';
import { Text } from '@fluentui/react-northstar';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const LicenseErrorNoPlans = () => (
    <>
        <Text align="start" style={{ marginBottom: '16px', width: '90%' }}>
            <FormattedMessage {...messages.failedConfigurationLicensesNoPlans1} />
        </Text>
        <Text align="start" style={{ marginBottom: '16px', width: '90%' }}>
            <FormattedMessage {...messages.failedConfigurationLicensesNoPlans2} />
        </Text>
    </>
);

export default LicenseErrorNoPlans;
