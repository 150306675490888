import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as FullStory from '@fullstory/browser';

FullStory.init({
    orgId: process.env.REACT_APP_FULLSTORY_ORG_ID || '',
    devMode: process.env.REACT_APP_FULLSTORY_DEV_MODE === 'true' || false,
    host: process.env.REACT_APP_FULLSTORY_HOST || 'fullstory.com',
    script: process.env.REACT_APP_FULLSTORY_SCRIPT || 'edge.eu1.fullstory.com/s/fs.js',
    namespace: process.env.REACT_APP_FULLSTORY_NAMESPACE || 'FS',
});

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(<App />);
