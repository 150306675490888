export const APP_ROUTES = {
    WELCOME: '/',
    TENANT: '/tenant',
    COMPANY_DETAILS: '/tenant/company-details',
    ACCOUNT_NOT_FOUND: '/tenant/not-found',
    INCORRECT_COMPANY: '/tenant/incorrect-company',
    CONFIGURE: '/tenant/configure',
    CONFIGURE_SUCCESS: '/tenant/success',
    CONFIGURE_FAILED: '/tenant/failed',
    CONFIGURE_FAILED_LICENSES: '/tenant/no-licenses',
    REQUIRES_SYNC: '/tenant/sync',
    MANAGE_USERS_NOT_FOUND: '/manage-users-not-found',
    MANAGE_USERS: '/manage-users',
    HELP: '/help',
    PRIVACY: '/privacy',
    DIAGNOSTICS: '/diagnostics',
};
