import * as React from 'react';
import { Accordion, Text } from '@fluentui/react-northstar';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const panels = [
    {
        title: (
            <Text align="start" key="p2t1">
                <FormattedMessage
                    {...messages.failedConfigurationLicensesNoAvailableLicensesAccTitle2}
                />
            </Text>
        ),
        content: (
            <Text align="start" key="p2c1">
                <FormattedMessage
                    {...messages.failedConfigurationLicensesNoAvailableLicensesAccMain2}
                />
            </Text>
        ),
    },
    {
        title: (
            <Text align="start" key="p3t1">
                <FormattedMessage
                    {...messages.failedConfigurationLicensesNoAvailableLicensesAccTitle3}
                />
            </Text>
        ),
        content: (
            <Text align="start" key="p3c1">
                <FormattedMessage
                    {...messages.failedConfigurationLicensesNoAvailableLicensesAccMain3}
                />
            </Text>
        ),
    },
];

const LicenseErrorAccordianNoneAvailable = () => <Accordion panels={panels} exclusive />;

export default LicenseErrorAccordianNoneAvailable;
