import React from 'react';
import { Flex, Loader } from '@fluentui/react-northstar';

type SpinnerProps = {
    label?: string | React.ReactNode;
    isLoading: boolean;
    children?: React.ReactNode;
    size?: 'smallest' | 'smaller' | 'small' | 'medium' | 'large' | 'larger' | 'largest';
};

const Spinner = ({ label, size = 'small', isLoading, children, ...rest }: SpinnerProps & any) => {
    return isLoading ? (
        <Flex column={true} {...rest}>
            <Loader
                label={label}
                labelPosition="end"
                size={size}
                style={{ color: '#6264A7', justifyContent: 'flex-start', marginTop: '30px' }}
            />
        </Flex>
    ) : (
        <>{children}</>
    );
};

export default Spinner;
