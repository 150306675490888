import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useNavigate } from 'react-router-dom';
import { ExclamationTriangleIcon, Flex } from '@fluentui/react-northstar';

import StatusTableLayout from 'components/Layout/StatusTableLayout';

import { STATE_ROUTES, STATE_STATUSES } from '../../constants/stateRoutes';

import { useResumeConfigurationQuery, useTenantQuery } from './module/services';

import messages from './messages';
import { APP_ROUTES } from 'routes/constants';
import LicenseErrorNoneAvailable from './LicenseDetails/LicenseErrorNoneAvailable';
import LicenseErrorNoPlans from './LicenseDetails/LicenseErrorNoPlans';
import { useResponsive } from '../../hooks/useResponsive';

const FailedLicensesConfigurations = () => {
    const mutation = useResumeConfigurationQuery(),
        { data } = useTenantQuery(),
        { isMobile } = useResponsive(),
        history = useNavigate();

    if (mutation.data?.status && mutation.data?.status !== STATE_STATUSES.REQUIRES_LICENSES) {
        return <Navigate to={STATE_ROUTES[mutation.data.status]} />;
    }

    console.log(JSON.stringify(data?.stage?.details || ''));

    let licenseData;
    try {
        licenseData = JSON.parse(data?.stage?.details || '');
    } catch (error) {
        console.error(error);
        licenseData = 'nodata';
    }

    console.log(licenseData);

    return (
        <div className="fs-unmask">
            <StatusTableLayout
                image={
                    <Flex
                        styles={
                            isMobile
                                ? {
                                      marginLeft: 'auto',
                                      marginRight: 'auto',
                                  }
                                : {}
                        }
                    >
                        <ExclamationTriangleIcon outline size="largest" />
                    </Flex>
                }
                heading={<FormattedMessage {...messages.failedConfigurationLicenseHeading2} />}
                content={
                    <>
                        {typeof licenseData !== 'object' && <LicenseErrorNoneAvailable />}
                        {typeof licenseData === 'object' &&
                            licenseData.usableSubscriptions.length == 0 && <LicenseErrorNoPlans />}
                        {typeof licenseData === 'object' &&
                            licenseData.usableSubscriptions.length > 0 && (
                                <LicenseErrorNoneAvailable />
                            )}
                    </>
                }
                action={() => {
                    mutation.mutate();
                    history(APP_ROUTES.CONFIGURE);
                }}
                actionText={<FormattedMessage {...messages.retrySetup} />}
                actionStyles={{ width: '280px', align: 'end', float: 'right' }}
                actionLoading={mutation?.isLoading}
            />
        </div>
    );
};

export default FailedLicensesConfigurations;
