import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ExclamationTriangleIcon, Text } from '@fluentui/react-northstar';

import StatusLayout from 'components/Layout/StatusLayout';

import { APP_ROUTES } from '../../routes/constants';

import messages from './messages';

const IncorrectCompany = () => {
    return (
        <StatusLayout
            image={<ExclamationTriangleIcon outline size="largest" />}
            heading={<FormattedMessage {...messages.incorrectCompanyHeading} />}
            content={
                <>
                    <Text align="center" style={{ marginBottom: '10px' }}>
                        <FormattedMessage {...messages.incorrectCompanyDescription1} />
                    </Text>
                    <Text align="center" style={{ marginBottom: '10px' }}>
                        <FormattedMessage
                            {...messages.incorrectCompanyDescription2}
                            values={{
                                link: (
                                    <Link to={APP_ROUTES.COMPANY_DETAILS}>
                                        <FormattedMessage {...messages.restartProcess} />
                                    </Link>
                                ),
                            }}
                        />
                    </Text>
                </>
            }
        />
    );
};

export default IncorrectCompany;
