import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useNavigate } from 'react-router-dom';
import { ExclamationTriangleIcon, Text } from '@fluentui/react-northstar';

import StatusLayout from 'components/Layout/StatusLayout';

import { STATE_ROUTES, STATE_STATUSES } from '../../constants/stateRoutes';

import { useRetryConfigurationQuery } from './module/services';

import messages from './messages';
import { APP_ROUTES } from 'routes/constants';

const FailedConfiguration = () => {
    const mutation = useRetryConfigurationQuery();
    const history = useNavigate();

    if (mutation.data?.status && mutation.data?.status !== STATE_STATUSES.REQUIRES_LICENSES) {
        return <Navigate to={STATE_ROUTES[mutation.data.status]} />;
    }

    return (
        <StatusLayout
            image={<ExclamationTriangleIcon outline size="largest" />}
            heading={<FormattedMessage {...messages.failedConfigurationHeading} />}
            content={
                <>
                    <Text align="center" style={{ marginBottom: '10px' }}>
                        <FormattedMessage {...messages.failedConfigurationText1} />
                    </Text>
                    <Text align="center" style={{ marginBottom: '30px' }}>
                        <FormattedMessage {...messages.failedConfigurationText2} />
                    </Text>
                </>
            }
            action={() => {
                mutation.mutate();
                history(APP_ROUTES.CONFIGURE);
            }}
            actionLoading={mutation.isLoading}
            actionText={<FormattedMessage {...messages.retrySetup} />}
            actionStyles={{ width: '280px' }}
        />
    );
};

export default FailedConfiguration;
