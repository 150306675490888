// eslint-disable-next-line import/named
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';

import { QUERY_KEYS } from 'constants/queryKeys';
import { httpFetch } from 'utils/httpFetch';

import { IStartTenantConfig, ITenantStatusError } from './types';

export const getTenantStatus = async () => {
    try {
        const response = await httpFetch(`/tenant`);
        return response.data;
    } catch (error) {
        console.error(`getTenantStatus error: ${error}`);
        throw error;
    }
};

const startTenantConfiguration = async () => {
    try {
        const response = await httpFetch.post(`/tenant`);
        return response.data;
    } catch (error) {
        console.error(`startTenantConfiguration error: ${error}`);
        throw error;
    }
};

const retryTenantConfiguration = async () => {
    try {
        const response = await httpFetch.put(`/tenant`);
        return response.data;
    } catch (error) {
        console.error(`retryTenantConfiguration error: ${error}`);
        throw error;
    }
};

const resumeTenantConfiguration = async () => {
    try {
        const response = await httpFetch.patch(`/tenant`);

        return response.data;
    } catch (error) {
        console.error(`resumeTenanatConfiguration error: ${error}`);
        throw error;
    }
};

export const useTenantQuery = (options?: UseQueryOptions<IStartTenantConfig, ITenantStatusError>) =>
    useQuery<IStartTenantConfig, ITenantStatusError>([QUERY_KEYS.TENANT], getTenantStatus, {
        retry: false,
        ...options,
    });

export const useStartConfigurationQuery = () => {
    const queryClient = useQueryClient();
    return useMutation<IStartTenantConfig>(startTenantConfiguration, {
        onMutate: () => queryClient.invalidateQueries([QUERY_KEYS.TENANT]),
    });
};

export const useRetryConfigurationQuery = () =>
    useMutation<IStartTenantConfig>(retryTenantConfiguration);
export const useResumeConfigurationQuery = () =>
    useMutation<IStartTenantConfig>(resumeTenantConfiguration);
