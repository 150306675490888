import { create } from 'zustand';

type State = {
    isNonAdminError: boolean;
    setNonAdminErrorVisibility: (isVisible: boolean) => void;
    isValidatingPermissions: boolean;
    setValidatingPermissions: (hasPermissions: boolean) => void;
    hasValidPermissions: boolean;
    setHasValidPermissions: (hasPermissions: boolean) => void;
    validatingPermissionsTimeout: number | undefined;
    setValidatingPermissionsTimeout: (timeout: number | undefined) => void;
    isTokenInvalid: boolean;
    setIsTokenInvalid: (isInvalid: boolean) => void;
    isTerminateApp: boolean;
    setTerminateApp: (isTerminateApp: boolean) => void;
    webAppUrl: string | undefined;
    setWebAppUrl: (webAppUrl: string) => void;
    displayMessage: any;
    setDisplayMessage: (displayMessage: { title: string; message: string }) => void;
};

export const useGlobalStore = create<State>((set) => ({
    isNonAdminError: false,
    setNonAdminErrorVisibility: (isVisible: boolean) => set(() => ({ isNonAdminError: isVisible })),
    isValidatingPermissions: true,
    setValidatingPermissions: (isValidating: boolean) =>
        set(() => ({ isValidatingPermissions: isValidating })),
    hasValidPermissions: false,
    setHasValidPermissions: (hasPermissions: boolean) =>
        set(() => ({ isValidatingPermissions: hasPermissions })),
    validatingPermissionsTimeout: undefined,
    setValidatingPermissionsTimeout: (timeout: number | undefined) =>
        set(() => ({ validatingPermissionsTimeout: timeout })),
    isTokenInvalid: false,
    setIsTokenInvalid: (isInvalid: boolean) => set(() => ({ isTokenInvalid: isInvalid })),
    isTerminateApp: false,
    setTerminateApp: (isTerminateApp: boolean) => set(() => ({ isTerminateApp: isTerminateApp })),
    webAppUrl: undefined,
    setWebAppUrl: (webAppUrl: string | undefined) => set(() => ({ webAppUrl: webAppUrl })),
    displayMessage: undefined,
    setDisplayMessage: (displayMessage: { title: string; message: string }) =>
        set(() => ({ displayMessage: displayMessage })),
}));
