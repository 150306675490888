import React from 'react';

const History = () => {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2 8V20H14"
                stroke="#212121"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.02 30C8.31679 33.6808 10.7747 36.8404 14.0233 39.0028C17.272 41.1652 21.1354 42.2132 25.0314 41.9889C28.9275 41.7647 32.6452 40.2804 35.6243 37.7595C38.6034 35.2387 40.6825 31.818 41.5484 28.0127C42.4144 24.2075 42.0202 20.2239 40.4252 16.6622C38.8303 13.1005 36.1211 10.1536 32.7057 8.26553C29.2903 6.37747 25.3538 5.65053 21.4894 6.19424C17.6249 6.73794 14.0418 8.52285 11.28 11.28L2 20"
                stroke="#212121"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M24 12V24L32 28"
                stroke="#212121"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default History;
