import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useNavigate } from 'react-router-dom';
import { Text } from '@fluentui/react-northstar';
import History from '../../assets/icons/History';

import StatusLayout from 'components/Layout/StatusLayout';

import { STATE_ROUTES, STATE_STATUSES } from '../../constants/stateRoutes';

import { useResumeConfigurationQuery, useTenantQuery } from './module/services';

import messages from './messages';
import { APP_ROUTES } from 'routes/constants';

const RequiredSync = () => {
    const mutation = useResumeConfigurationQuery(),
        { data } = useTenantQuery(),
        history = useNavigate();

    if (data?.status && data?.status !== STATE_STATUSES.REQUIRES_SYNC) {
        return <Navigate to={STATE_ROUTES[data.status]} />;
    }

    return (
        <StatusLayout
            image={<History />}
            heading={<FormattedMessage {...messages.syncRequiredHeading} />}
            content={
                <>
                    <Text align="center" style={{ marginBottom: '37px' }}>
                        <FormattedMessage {...messages.syncRequiredText1} />
                    </Text>
                </>
            }
            action={() => {
                mutation.mutate();
                history(APP_ROUTES.CONFIGURE);
            }}
            actionLoading={mutation.isLoading}
            actionText={<FormattedMessage {...messages.syncAccount} />}
            actionStyles={{ width: '280px' }}
        />
    );
};

export default RequiredSync;
