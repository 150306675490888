import React from 'react';
import { Text } from '@fluentui/react-northstar';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import LicenseErrorAccordianNoneAvailable from './LicenseErrorAccordianNoneAvailable';

const LicenseErrorNoneAvailable = () => (
    <>
        <Text align="start" style={{ marginBottom: '16px', width: '90%' }}>
            <FormattedMessage {...messages.failedConfigurationLicensesNoAvailableLicenses1} />
        </Text>
        <Text align="start" style={{ marginBottom: '2px', width: '90%' }}>
            <FormattedMessage {...messages.failedConfigurationLicensesNoAvailableLicenses2} />
        </Text>
        <LicenseErrorAccordianNoneAvailable />
    </>
);

export default LicenseErrorNoneAvailable;
