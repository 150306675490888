import { defineMessages } from 'react-intl';

const messages = defineMessages({
    appName: {
        id: 'general.appName',
        defaultMessage: 'Gamma Voice',
    },
    manageUsers: {
        id: 'general.manage_users',
        defaultMessage: 'Manage Users',
    },
    tenantConfiguration: {
        id: 'general.tenant_configuration',
        defaultMessage: 'Configure',
    },
    help: {
        id: 'general.help',
        defaultMessage: 'Help',
    },
    diagnostics: {
        id: 'general.diagnostics',
        defaultMessage: 'Diagnostics',
    },
    logout: {
        id: 'general.logout',
        defaultMessage: 'Sign out',
    },
});

export default messages as typeof messages;
