import React from 'react';
import { Navigate } from 'react-router-dom';
import { APP_ROUTES } from 'routes/constants';

import { useTenantQuery } from './module/services';
import { STATE_FAILURE, STATE_ROUTES } from '../../constants/stateRoutes';
import MainLayout from '../../components/Layout/MainLayout';
import Spinner from '../../components/Spinner';

const TenantConfiguration = () => {
    const { isLoading, data, error } = useTenantQuery(); // request 2s

    if (data?.hasConsent == false) {
        console.debug('routing to admin consent comp');
        return <Navigate to={APP_ROUTES.WELCOME} />;
    }
    if (data?.status) {
        return <Navigate to={STATE_ROUTES[data.status]} />;
    }
    if ((error as any)?.response?.data?.message === STATE_FAILURE.ACCOUNT_NOT_FOUND) {
        return <Navigate to={APP_ROUTES.ACCOUNT_NOT_FOUND} />;
    }

    return (
        <MainLayout>
            <Spinner isLoading={isLoading} hAlign="center" vAlign={'center'}>
                Redirection...
            </Spinner>
        </MainLayout>
    );
};

export default TenantConfiguration;
