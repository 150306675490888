import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate } from 'react-router-dom';
import { Loader, Text } from '@fluentui/react-northstar';

import MainLayout from 'components/Layout/MainLayout';
import { STATE_ROUTES, STATE_STATUSES } from 'constants/stateRoutes';

import { useTenantQuery } from './module/services';

import messages from './messages';

const Configure = () => {
    const { data, isFetching } = useTenantQuery({ retry: true, refetchInterval: 5000 });
    let messageKey = `configure_${data?.stage?.name || 'DOMAIN'}` as keyof typeof messages;

    if (messages[messageKey] == undefined) {
        messageKey = 'configure_FALLBACK';
    }

    let attempt = 0;
    if (messageKey === 'configure_VOICE_ROUTE') {
        if (data?.stage?.details && data?.stage?.details.includes('attempt')) {
            attempt = JSON.parse(data?.stage?.details).attempt;
            messageKey = `configure_VOICE_ROUTE_WITH_ATTEMPT` as keyof typeof messages;
        }
    }

    if (!isFetching && data?.status && data?.status !== STATE_STATUSES.ONGOING) {
        return <Navigate to={STATE_ROUTES[data.status]} />;
    }

    return (
        <MainLayout maxWidth={'550px'}>
            <Text className="fs-unmask" as="h2" weight="bold" styles={{ marginBottom: '5px' }}>
                <FormattedMessage {...messages.configureHeading} />
            </Text>
            <Text className="fs-unmask">
                {data?.domainValidationTakingLongerThanExpected === true ? (
                    <>
                        <Text>
                            <FormattedMessage
                                {...messages.configureIsTakingLongerDescriptionTitle}
                            />
                        </Text>
                        <br />
                        <Text className="fs-mask">
                            <FormattedMessage
                                {...messages.configureIsTakingLongerDescription}
                                values={{ emailList: data?.startedBy?.join(', ') || '' }}
                            />
                        </Text>
                    </>
                ) : (
                    <FormattedMessage {...messages.configureDescription} />
                )}
            </Text>
            <Loader
                className="fs-unmask"
                label={
                    <FormattedMessage
                        {...messages[messageKey]}
                        values={attempt ? { attempt: `${attempt}` } : undefined}
                    />
                }
                labelPosition="end"
                size="small"
                style={{ color: '#6264A7', justifyContent: 'flex-start', marginTop: '30px' }}
            />
        </MainLayout>
    );
};

export default Configure;
