import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useGlobalStore } from 'store/Globalstore';
import Spinner from '../components/Spinner';
import { APP_ROUTES } from './constants';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router';
import { useTokens } from '../hooks/useTokens';
import { useResponsive } from '../hooks/useResponsive';
import NavigationBarMobile from './NavigationBar/NavigationBarMobile';
import NavigationBar from './NavigationBar/NavigationBar';
import CompanyDetails from '../features/TenantConfiguration/CompanyDetails';
import AccountNotFound from '../features/TenantConfiguration/AccountNotFound';
import IncorrectCompany from '../features/TenantConfiguration/IncorrectCompany';
import Configure from '../features/TenantConfiguration/Configure';
import ConfigureSuccess from '../features/TenantConfiguration/ConfigureSuccess';
import FailedConfiguration from '../features/TenantConfiguration/FailedConfiguration';
import RequiredSync from '../features/TenantConfiguration/RequiredSync';
import FailedLicensesConfigurations from '../features/TenantConfiguration/FailedLicensesConfigurations';
import TenantConfiguration from '../features/TenantConfiguration';

const Help = lazy(() => import('features/Help'));
const Welcome = lazy(() => import('features/Welcome'));
// const TenantConfiguration = lazy(() => import('features/TenantConfiguration'));
const NotFound = lazy(() => import('features/NotFound'));
const ManageUsers = lazy(() => import('features/ManageUsers'));
const Diagnostics = lazy(() => import('features/Diagnostics'));
const ManageUsersNotFound = lazy(() => import('features/ManageUsers/ManageUsersNotFound'));
const PrivacyPolicy = lazy(() => import('../features/PrivacyPolicy'));
const AppRouter = () => {
    const [, setClientToken] = useLocalStorage('client_token');
    const setIsTokenInvalid = useGlobalStore((state) => state.setIsTokenInvalid);
    const { instance } = useMsal();
    const history = useNavigate();
    const { acquireAccessToken } = useTokens();
    const { isTabletOrMobile } = useResponsive();

    const onLogout = (instance: any) => {
        instance.logoutRedirect({ postLogoutRedirectUri: APP_ROUTES.WELCOME }).catch((e: any) => {
            console.error(e);
        });
        setClientToken('');
        setIsTokenInvalid(false);
        history(APP_ROUTES.WELCOME);
    };

    const handleUnauthorized = () => {
        window.addEventListener('UNAUTHORIZED', () => {
            window.removeEventListener('UNAUTHORIZED', () => undefined);
            acquireAccessToken(instance)
                .then((accessToken) => {
                    console.debug('Acquired token is: ', accessToken);
                    setIsTokenInvalid(false);
                    setClientToken(accessToken);
                    handleUnauthorized();
                })
                .catch(() => {
                    console.debug('Unable to acquire token, redirecting to welcome');
                    setIsTokenInvalid(true);
                    setClientToken('');
                    handleUnauthorized();
                    window.location.href = APP_ROUTES.WELCOME;
                });
        });
    };

    useEffect(() => {
        handleUnauthorized();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {isTabletOrMobile && <NavigationBarMobile onLogout={onLogout} />}
            {!isTabletOrMobile && <NavigationBar onLogout={onLogout} />}
            <Suspense
                fallback={
                    <Spinner
                        hAlign="center"
                        vAlign="center"
                        size="medium"
                        isLoading={true}
                        styles={{ height: '105vh' }}
                    />
                }
            >
                <Routes>
                    <Route index path={APP_ROUTES.WELCOME} element={<Welcome />} />

                    {/*TENANT CONFIGURATION*/}
                    <Route path={APP_ROUTES.TENANT} element={<TenantConfiguration />} />
                    <Route path={APP_ROUTES.COMPANY_DETAILS} element={<CompanyDetails />} />
                    <Route path={APP_ROUTES.ACCOUNT_NOT_FOUND} element={<AccountNotFound />} />
                    <Route path={APP_ROUTES.INCORRECT_COMPANY} element={<IncorrectCompany />} />
                    <Route path={APP_ROUTES.CONFIGURE} element={<Configure />} />
                    <Route path={APP_ROUTES.CONFIGURE_SUCCESS} element={<ConfigureSuccess />} />
                    <Route path={APP_ROUTES.CONFIGURE_FAILED} element={<FailedConfiguration />} />
                    <Route path={APP_ROUTES.REQUIRES_SYNC} element={<RequiredSync />} />
                    <Route
                        path={APP_ROUTES.CONFIGURE_FAILED_LICENSES}
                        element={<FailedLicensesConfigurations />}
                    />
                    {/*TENANT CONFIGURATION*/}

                    <Route
                        path={APP_ROUTES.MANAGE_USERS_NOT_FOUND}
                        element={<ManageUsersNotFound />}
                    />
                    <Route path={APP_ROUTES.MANAGE_USERS} element={<ManageUsers />} />
                    <Route path={APP_ROUTES.HELP} element={<Help />} />
                    <Route path={APP_ROUTES.PRIVACY} element={<PrivacyPolicy />} />
                    <Route path={APP_ROUTES.DIAGNOSTICS} element={<Diagnostics />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="*/*" element={<NotFound />} />
                </Routes>
            </Suspense>
        </>
    );
};

export default AppRouter;
