import { useNavigate } from 'react-router';
import { APP_ROUTES } from '../routes/constants';

import { useLocalStorage } from './useLocalStorage';
import { loginRequest } from '../msalConfig';
// eslint-disable-next-line import/named
import { IPublicClientApplication } from '@azure/msal-browser/dist/app/IPublicClientApplication';
import jwt_decode from 'jwt-decode';

export const useTokens = () => {
    const [, setClientToken] = useLocalStorage('client_token');
    const history = useNavigate();
    const [activeUser] = useLocalStorage('active_user');

    const handleActiveUser = (msalInstance: IPublicClientApplication) => {
        const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
        const accounts = msalInstance.getAllAccounts();
        let account = undefined;
        console.debug('handleActiveUser activeAccount', activeAccount);
        console.debug('handleActiveUser activeAccounts', accounts);
        if (!activeAccount && accounts.length === 0) {
            history(APP_ROUTES.WELCOME);
        }

        if (activeAccount) {
            return activeAccount;
        } else {
            account = accounts.find((acc) => acc?.username === activeUser);
        }

        return account ? account : accounts[0];
    };

    const returnEmptyToken = (
        msalInstance: IPublicClientApplication,
        request: { scopes: string[]; prompt: string; account: any }
    ) => {
        console.debug('acquireAccessToken wrong active user');
        setClientToken('');
        msalInstance.acquireTokenRedirect(request);
        return '';
    };

    const acquireAccessToken = async (
        msalInstance: IPublicClientApplication,
        forceRefresh = false
    ) => {
        console.debug('acquireAccessToken active user', handleActiveUser(msalInstance));
        const request = {
            scopes: loginRequest.scopes,
            prompt: 'select_account',
            account: handleActiveUser(msalInstance),
            forceRefresh: forceRefresh,
        };
        try {
            const authResult = await msalInstance.acquireTokenSilent(request);
            const decodedJWT: any = jwt_decode(authResult.accessToken);
            console.debug(
                'acquireAccessToken decoded JWT',
                decodedJWT?.upn,
                decodedJWT?.preferred_username,
                activeUser
            );
            if (
                (decodedJWT?.ver === '1.0' || decodedJWT?.ver == null) &&
                activeUser !== decodedJWT?.upn
            ) {
                return returnEmptyToken(msalInstance, request);
            } else if (decodedJWT?.ver === '2.0' && activeUser !== decodedJWT?.preferred_username) {
                return returnEmptyToken(msalInstance, request);
            }

            return authResult.accessToken;
        } catch (e) {
            msalInstance.acquireTokenRedirect(request).catch((error) => console.error(error));
        }
    };

    return {
        acquireAccessToken,
    };
};
