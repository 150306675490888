import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  a {
	color: #6264A7;
	text-decoration: none;
	&:hover {
	  text-decoration: underline;
	}
    &.ui-button:hover {
      text-decoration: none;
    }
  }
  .ui-avatar {
    .ui-label {
      font-weight: bold;
      font-size: 10px;
    }
    .ui-status {
      .ui-box {
        width: 16px;
        height: 16px;
      }
    }
  }
  .ui-alert__icon {
	width: 24px;
	height: 20px;
  }
  .dropzone {
	outline: none;
    border: 1.6705px dashed #686868;
	width: 80%;
	margin: 0 auto;
	padding: 35px;
  }
`;
