import { defineMessages } from 'react-intl';

const messages = defineMessages({
    continue: {
        id: 'general.continue',
        defaultMessage: 'Continue',
    },
    restartProcess: {
        id: 'features.TenantConfiguration.restartProcess',
        defaultMessage: 'restart the set-up process',
    },
    retrySetup: {
        id: 'features.TenantConfiguration.retrySetup',
        defaultMessage: 'Retry set-up',
    },
    accountNotFoundHeading: {
        id: 'features.TenantConfiguration.AccountNotFound.accountNotFoundHeading',
        defaultMessage: 'We can’t find your account',
    },
    accountNotFoundDescription1: {
        id: 'features.TenantConfiguration.AccountNotFound.AccountNotFoundDescription1',
        defaultMessage:
            'To use Gamma Voice you will need to place an order with an approved supplier.',
    },
    accountNotFoundDescription2: {
        id: 'features.TenantConfiguration.AccountNotFound.AccountNotFoundDescription2',
        defaultMessage:
            'Already placed an order? Contact your administrator or the company who is supplying Gamma Voice to you, then {link}.',
    },
    companyDetailsHeading: {
        id: 'features.TenantConfiguration.CompanyDetails.companyDetailsHeading',
        defaultMessage: 'Company details',
    },
    companyDetailsDescription: {
        id: 'features.TenantConfiguration.CompanyDetails.companyDetailsDescription',
        defaultMessage: 'Before set-up, check the details for your organisation are correct.',
    },
    companyDetailsCompanyName: {
        id: 'features.TenantConfiguration.CompanyDetails.companyDetailsCompanyName',
        defaultMessage: 'Company name:',
    },
    companyDetailsCompanyConfirm: {
        id: 'features.TenantConfiguration.CompanyDetails.companyDetailsCompanyConfirm',
        defaultMessage: 'I confirm this is the correct company',
    },
    companyDetailsCorrectDetailsQuestion: {
        id: 'features.TenantConfiguration.CompanyDetails.companyDetailsCorrectDetailsQuestion',
        defaultMessage: 'Not the correct details?',
    },
    companyDetailsDetailsLink: {
        id: 'features.TenantConfiguration.CompanyDetails.companyDetailsDetailsLink',
        defaultMessage: 'I don’t recognise these details',
    },
    adminConsentRedirectLink: {
        id: 'features.TenantConfiguration.AdminConsent.adminConsentRedirectLink',
        defaultMessage: 'Validating admin permissions, this may take a few minutes...',
    },
    incorrectCompanyHeading: {
        id: 'features.TenantConfiguration.IncorrectCompany.IncorrectCompanyHeading',
        defaultMessage: 'Incorrect company information',
    },
    incorrectCompanyDescription1: {
        id: 'features.TenantConfiguration.IncorrectCompany.IncorrectCompanyDescription1',
        defaultMessage:
            'The company that is supplying your direct routing service has given the wrong details.',
    },
    incorrectCompanyDescription2: {
        id: 'features.TenantConfiguration.IncorrectCompany.IncorrectCompanyDescription2',
        defaultMessage:
            'You will need to contact them and ask them to update your details, then you can {link}.',
    },
    configureSuccessHeading: {
        id: 'features.TenantConfiguration.ConfigureSuccess.heading',
        defaultMessage: 'Configuration complete',
    },
    configureSuccessDescription: {
        id: 'features.TenantConfiguration.ConfigureSuccess.description',
        defaultMessage: 'Your Microsoft organisation has been configured for use with Gamma Voice',
    },
    configureSuccessGetStartedButton: {
        id: 'features.TenantConfiguration.ConfigureSuccess.getStartedButton',
        defaultMessage: 'Go to manage users',
    },
    configureHeading: {
        id: 'features.TenantConfiguration.Configure.heading',
        defaultMessage: 'Setting up Gamma Voice',
    },
    configureDescription: {
        id: 'features.TenantConfiguration.Configure.description',
        defaultMessage:
            'When the process is complete, you will be able to make calls through Teams.',
    },
    configureIsTakingLongerDescription: {
        id: 'features.TenantConfiguration.Configure.longerThanExpected',
        defaultMessage:
            'When this is complete we will send an email to {emailList}. Then you can begin the set-up.',
    },
    configureIsTakingLongerDescriptionTitle: {
        id: 'features.TenantConfiguration.Configure.longerThanExpectedTitle',
        defaultMessage:
            'This step may take from 10 minutes to 72 hours. Please do not close the APP',
    },
    configure_DOMAIN: {
        id: 'features.TenantConfiguration.Configure.configure_DOMAIN',
        defaultMessage: 'Registering domains. This will take up to 10 mins',
    },
    configure_PSTN_USAGE: {
        id: 'features.TenantConfiguration.Configure.configure_PSTN_USAGE',
        defaultMessage: 'PSTN usage',
    },
    configure_VOICE_ROUTE: {
        id: 'features.TenantConfiguration.Configure.configure_VOICE_ROUTE',
        defaultMessage: 'Voice routing',
    },
    configure_VOICE_ROUTE_WITH_ATTEMPT: {
        id: 'features.TenantConfiguration.Configure.configure_VOICE_ROUTE_WITH_ATTEMPT',
        defaultMessage: 'Voice routing (Retry attempts: {attempt})',
    },
    configure_VOICE_ROUTE_POLICY: {
        id: 'features.TenantConfiguration.Configure.configure_VOICE_ROUTE_POLICY',
        defaultMessage: 'Voice route policy',
    },
    configure_CALLING_POLICY: {
        id: 'features.TenantConfiguration.Configure.configure_CALLING_POLICY',
        defaultMessage: 'Calling policy',
    },
    configure_DIAL_PLAN: {
        id: 'features.TenantConfiguration.Configure.configure_DIAL_PLAN',
        defaultMessage: 'Dial plan',
    },
    configure_REMOVE_DUMMY_USERS_ASSIGNED_LICENSES: {
        id: 'features.TenantConfiguration.Configure.configure_REMOVE_DUMMY_USERS_ASSIGNED_LICENSESS',
        defaultMessage: 'Licenses cleanup',
    },
    configure_SYNC_ACCOUNTS: {
        id: 'features.TenantConfiguration.Configure.configure_SYNC_ACCOUNTS',
        defaultMessage: 'Syncing accounts',
    },
    configure_FALLBACK: {
        id: 'features.TenantConfiguration.Configure.configure_FALLBACK',
        defaultMessage: 'Configuring',
    },
    failedConfigurationLicenseHeading2: {
        id: 'features.TenantConfiguration.FailedConfiguration.failedConfigurationLicenseHeading2',
        defaultMessage: 'Something’s gone wrong',
    },
    failedConfigurationText1: {
        id: 'features.TenantConfiguration.FailedConfiguration.failedConfigurationText1',
        defaultMessage: 'We can’t complete your set-up right now. ',
    },
    failedConfigurationText2: {
        id: 'features.TenantConfiguration.FailedConfiguration.failedConfigurationText2',
        defaultMessage: 'Try the set-up again another time.',
    },
    failedConfigurationHeading: {
        id: 'features.TenantConfiguration.FailedConfiguration.failedConfigurationHeading',
        defaultMessage: 'Something’s gone wrong',
    },
    syncRequiredHeading: {
        id: 'features.TenantConfiguration.RequiredSync.heading',
        defaultMessage: 'Reload assigned users',
    },
    syncRequiredText1: {
        id: 'features.TenantConfiguration.RequiredSync.syncRequiredText1',
        defaultMessage:
            'We need to check if any changes have been made outside of the Gamma Voice application to make sure everything is up to date.',
    },
    syncAccount: {
        id: 'features.TenantConfiguration.RequiredSync.syncAccount',
        defaultMessage: 'Reload assigned users',
    },
});

export default messages as typeof messages;
