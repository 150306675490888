import * as msal from '@azure/msal-browser';

const DEFAULT_CLIENT_ID = '222434d2-de9c-47c4-8afc-5fc1f7efa46c';

export const msalConfig: msal.Configuration = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_APP_REGISTRATION_ID || DEFAULT_CLIENT_ID,
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: [`openid profile email offline_access ${process.env.REACT_APP_MS_API_SCOPE}`],
};

export const msalInstance = new msal.PublicClientApplication(msalConfig);
