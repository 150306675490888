import axios from 'axios';
import { loginRequest, msalInstance } from '../msalConfig';
import { AuthenticationResult } from '@azure/msal-common/dist/response/AuthenticationResult';
import jwt_decode from 'jwt-decode';

export const httpFetch = axios.create({
    baseURL: process.env.REACT_APP_BASE_API,
});

const emptyToken = (tokenUser: string, activeUser: string | null, request: any) => {
    console.debug('interceptors acquireTokenSilent decoded JWT', `"${tokenUser}"`, activeUser);
    localStorage.setItem('client_token', '');
    msalInstance.acquireTokenRedirect(request);
};

httpFetch.interceptors.request.use((config) => {
    //https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2799
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();
    const activeUser = localStorage.getItem('active_user');

    const request = {
        scopes: loginRequest.scopes,
        prompt: 'select_account',
        account:
            activeAccount || accounts.find((acc) => acc?.username === activeUser) || accounts[0],
    };
    msalInstance
        .acquireTokenSilent(request)
        .then((response: AuthenticationResult) => {
            localStorage.setItem('client_token', JSON.stringify(response.accessToken));
            const decodedJWT: any = jwt_decode(response.accessToken || '');
            if (
                (decodedJWT?.ver === '1.0' || decodedJWT?.ver == null) &&
                activeUser !== `"${decodedJWT?.upn}"`
            ) {
                emptyToken(decodedJWT?.upn, activeUser, request);
            } else if (
                decodedJWT?.ver === '2.0' &&
                activeUser !== `"${decodedJWT?.preferred_username}"`
            ) {
                emptyToken(decodedJWT?.pre, activeUser, request);
            }
        })
        .catch((e) => {
            console.log(`intercept acquire silent token failed, catching exception ${e}`);
        });

    const clientToken = localStorage.getItem('client_token');
    config.headers.Authorization = clientToken ? `Bearer ${JSON.parse(clientToken)}` : undefined;
    return config;
});

httpFetch.interceptors.response.use(undefined, async (error) => {
    if (error.response.status === 401) {
        console.debug('interceptors', error.response.status);
        const customEvent = new CustomEvent('UNAUTHORIZED');
        window.dispatchEvent(customEvent);
    }

    throw error;
});
