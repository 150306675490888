export const QUERY_KEYS = {
    TENANT: 'tenant',
    TENANT_RESET: 'tenant/reset',
    TENANT_AUDIT: 'tenant/audit',
    DIAGNOSTICS: 'tenant/diagnostic',
    BASKET: 'basket',
    DDI_USERS: 'ddi_users',
    EXPORT: 'user_export',
    STATUS: 'status',
    USERS: 'users',
    DR_ENDPOINT: 'dr_endpoint',
    AWS_CONNECTION: 'aws_connection',
    GAMMA_API_CONNECTION: 'gamma_api_connection',
    SYNC: 'sync',
};
