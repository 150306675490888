import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/named
import { Flex, Menu, MenuProps } from '@fluentui/react-northstar';
import { APP_ROUTES } from '../constants';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import AppIcon from '../../assets/icons/App';
import { useGlobalStore } from '../../store/Globalstore';
import { useMsal } from '@azure/msal-react';
import styled from 'styled-components';
import MenuIcon from '../../assets/icons/Menu';
import { useLocation, useNavigate } from 'react-router';

const StyledMenu = styled(Menu)<MenuProps>`
    padding: 16px 4px 16px 4px;
    width: 235px;
    background-color: #f5f5f5;
    position: absolute;
    top: 60px;
    border: 0;
    border-radius: initial;
    .ui-menu__itemwrapper {
        max-width: 227px;
        height: 32px;
        border-radius: 3px;
        background-color: #f5f5f5;
        border: 0;
    }

    .ui-menu__item {
        border-radius: 3px;
        border-bottom: 3px;
    }
`;

const NavigationBarMobile = (props: any) => {
    const { onLogout, forceIsTokenInvalid, forceIsMainPage } = props;
    const { instance } = useMsal();
    const isTokenInvalid = useGlobalStore((state) => state.isTokenInvalid);
    const [showMenu, setShowMenu] = useState(false);
    const [activeItem, setActiveItem] = useState<number>(0);

    const logoutStyleMobile = {
        marginTop: 20,
        height: 20,
        marginRight: 16,
        color: '#616161',
        lineHeight: '20px',
    };

    const linkStyleMobile = {
        color: '#484644',
        height: 32,
        lineHeight: '20px',
        fontWeight: 400,
        fontSize: 14,
    };

    const activeLinkStyleMobile = {
        height: 32,
        backgroundColor: '#fff',
        color: '#484644',
        lineHeight: '20px',
        fontWeight: 400,
        fontSize: 14,
        borderRadius: 3,
    };

    const appNameStyle = {
        lineHeight: '24px',
        fontSize: 18,
        fontWeight: 'bold' as const,
        height: 24,
        marginTop: 17,
    };

    const location = useLocation();
    const isMainPage = location.pathname === '/';
    const NavMenu = () => {
        const history = useNavigate();
        const handleActiveItem = () => {
            if (window.location.href.includes(APP_ROUTES.MANAGE_USERS)) {
                setActiveItem(1);
            } else if (window.location.href.includes(APP_ROUTES.TENANT)) {
                setActiveItem(0);
            } else if (window.location.href.includes(APP_ROUTES.DIAGNOSTICS)) {
                setActiveItem(2);
            } else if (window.location.href.includes(APP_ROUTES.HELP)) {
                setActiveItem(3);
            } else {
                setActiveItem(0);
            }
        };
        useEffect(() => {
            handleActiveItem();
        }, []);

        return (
            <>
                <Flex
                    data-testid="nav-menu-btn"
                    id="nav-menu-btn"
                    vAlign={'center'}
                    style={{
                        cursor: 'pointer',
                        padding: '18px 16px 18px',
                    }}
                    onClick={() => {
                        setShowMenu(!showMenu);
                    }}
                >
                    <MenuIcon />
                </Flex>
                {showMenu && (
                    <StyledMenu
                        data-testid={'nav-menu-mobile'}
                        vertical
                        styles={{
                            padding: 0,
                        }}
                        activeIndex={activeItem}
                        items={[
                            {
                                key: messages.tenantConfiguration.id,
                                content: <FormattedMessage {...messages.tenantConfiguration} />,
                                onClick: () => {
                                    history(APP_ROUTES.TENANT);
                                    setShowMenu(!showMenu);
                                    setActiveItem(0);
                                },
                                styles: activeItem == 0 ? activeLinkStyleMobile : linkStyleMobile,
                            },
                            {
                                key: messages.manageUsers.id,
                                content: <FormattedMessage {...messages.manageUsers} />,
                                onClick: () => {
                                    history(APP_ROUTES.MANAGE_USERS);
                                    setShowMenu(!showMenu);
                                    setActiveItem(1);
                                },
                                styles: activeItem == 1 ? activeLinkStyleMobile : linkStyleMobile,
                            },
                            {
                                key: messages.diagnostics.id,
                                content: <FormattedMessage {...messages.diagnostics} />,
                                onClick: () => {
                                    history(APP_ROUTES.DIAGNOSTICS);
                                    setShowMenu(!showMenu);
                                    setActiveItem(2);
                                },
                                styles: activeItem == 2 ? activeLinkStyleMobile : linkStyleMobile,
                            },
                            {
                                key: messages.help.id,
                                content: <FormattedMessage {...messages.help} />,
                                onClick: () => {
                                    history(APP_ROUTES.HELP);
                                    setShowMenu(!showMenu);
                                    setActiveItem(3);
                                },
                                styles: activeItem == 3 ? activeLinkStyleMobile : linkStyleMobile,
                            },
                        ]}
                    />
                )}
            </>
        );
    };

    return (
        <div
            className="fs-unmask"
            style={{
                width: '100%',
                minWidth: '320px',
                height: 60,
                backgroundColor: '#FFF',
                display: 'flex',
                flexDirection: 'row',
                borderBottomColor: '#EBEBEB',
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
            }}
        >
            {(!isMainPage || forceIsMainPage) && <NavMenu />}
            <Flex hAlign={'center'} styles={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <AppIcon />
                <div style={appNameStyle}>
                    <FormattedMessage {...messages.appName} />
                </div>
            </Flex>
            {((!isMainPage && !isTokenInvalid) || forceIsTokenInvalid) && (
                <NavLink
                    to={APP_ROUTES.WELCOME}
                    style={logoutStyleMobile}
                    onClick={() => onLogout(instance)}
                >
                    <FormattedMessage {...messages.logout} />
                </NavLink>
            )}
        </div>
    );
};

export default NavigationBarMobile;
