import { NavLink } from 'react-router-dom';
import AppIcon from '../../assets/icons/App';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import { APP_ROUTES } from '../constants';
import React, { CSSProperties } from 'react';
import { useGlobalStore } from '../../store/Globalstore';
import { useMsal } from '@azure/msal-react';
import { useLocation } from 'react-router';

const NavigationBar = (props: any) => {
    const { onLogout, forceIsMainPage = true } = props;
    const location = useLocation();
    const isMainPage = !forceIsMainPage
        ? forceIsMainPage
        : location.pathname === '/' || location.pathname === APP_ROUTES.PRIVACY;
    const isTokenInvalid = useGlobalStore((state) => state.isTokenInvalid);

    const { instance } = useMsal();

    const linkStyle = {
        marginTop: 20,
        height: 20,
        marginLeft: 16,
        color: '#616161',
        lineHeight: '20px',
    };

    const logoutStyle = {
        marginTop: 20,
        height: 20,
        marginLeft: 'auto',
        marginRight: 16,
        color: '#616161',
        lineHeight: '20px',
    };

    const activeLinkStyle = {
        marginTop: 20,
        height: 40,
        marginLeft: 16,
        color: '#242424',
        borderBottom: '2px solid #6264A7',
        borderRadius: '2px 2px 0px 0px',
    };

    const appNameStyle: CSSProperties = {
        lineHeight: '24px',
        fontSize: 18,
        fontWeight: 'bold' as const,
        height: 24,
        marginTop: 17,
    };

    return (
        <div
            className="fs-unmask"
            style={{
                width: '100%',
                height: 60,
                backgroundColor: '#FFF',
                display: 'flex',
                flexDirection: 'row',
                borderBottomColor: '#EBEBEB',
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
            }}
        >
            <AppIcon />
            <div style={appNameStyle}>
                <FormattedMessage {...messages.appName} />
            </div>
            {!isMainPage && (
                <>
                    <NavLink
                        style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
                        to={APP_ROUTES.TENANT}
                    >
                        <FormattedMessage {...messages.tenantConfiguration} />
                    </NavLink>
                    <NavLink
                        style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
                        to={APP_ROUTES.MANAGE_USERS}
                    >
                        <FormattedMessage {...messages.manageUsers} />
                    </NavLink>
                    <NavLink
                        style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
                        to={APP_ROUTES.DIAGNOSTICS}
                    >
                        <FormattedMessage {...messages.diagnostics} />
                    </NavLink>
                    <NavLink
                        style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
                        to={APP_ROUTES.HELP}
                    >
                        <FormattedMessage {...messages.help} />
                    </NavLink>
                    {!isTokenInvalid && (
                        <NavLink
                            to={APP_ROUTES.WELCOME}
                            style={logoutStyle}
                            onClick={() => onLogout(instance)}
                        >
                            <FormattedMessage {...messages.logout} />
                        </NavLink>
                    )}
                </>
            )}
        </div>
    );
};

export default NavigationBar;
