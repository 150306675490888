import React from 'react';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from '@fluentui/react-northstar';
import { useTeams } from 'msteams-react-base-component';
import locales from 'translations/locales';
import { BrowserRouter as Router } from 'react-router-dom';

import { GlobalStyles } from './GlobalStyles';
import { msalInstance } from './msalConfig';
import AppRouter from './routes';
import { useGlobalStore } from 'store/Globalstore';

import Spinner from 'components/Spinner';

// TODO: Use Context to change value dynamically
const locale = 'en';
const messages = locales[locale];

const App = () => {
    const [{ theme }] = useTeams();
    const queryClient = new QueryClient();
    const isTokenInvalid = useGlobalStore((state) => state.isTokenInvalid);

    return (
        <Router>
            <IntlProvider locale={locale} messages={messages}>
                <QueryClientProvider client={queryClient}>
                    <MsalProvider instance={msalInstance}>
                        <Provider theme={theme} styles={{ background: 'transparent' }}>
                            {isTokenInvalid && (
                                <div
                                    style={{
                                        backgroundColor: '#EFEFEF',
                                        position: 'fixed',
                                        width: '100%',
                                        height: '100%',
                                        zIndex: 1000,
                                        opacity: 0.5,
                                        top: '0px',
                                        left: '0px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Spinner vAlign={'center'} isLoading={isTokenInvalid} />
                                </div>
                            )}
                            <AppRouter />
                        </Provider>
                    </MsalProvider>
                </QueryClientProvider>
                <GlobalStyles />
            </IntlProvider>
        </Router>
    );
};

export default App;
