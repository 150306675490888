import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { Text } from '@fluentui/react-northstar';
import StatusLayout from 'components/Layout/StatusLayout';
import { APP_ROUTES } from 'routes/constants';

import messages from './messages';
import { ReactComponent as ConfigSuccessImg } from 'assets/images/config_success.svg';

const ConfigureSuccess = () => {
    const history = useNavigate();
    const handleClick = async () => history(APP_ROUTES.MANAGE_USERS);

    return (
        <StatusLayout
            width={'600px'}
            image={<ConfigSuccessImg />}
            heading={<FormattedMessage {...messages.configureSuccessHeading} />}
            content={
                <Text align="center" style={{ marginBottom: '30px' }}>
                    <FormattedMessage {...messages.configureSuccessDescription} />
                </Text>
            }
            action={() => handleClick()}
            actionText={<FormattedMessage {...messages.configureSuccessGetStartedButton} />}
        />
    );
};

export default ConfigureSuccess;
