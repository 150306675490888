import { APP_ROUTES } from 'routes/constants';

export const STATE_STATUSES = {
    NOT_CONFIGURED: 'NOT_CONFIGURED',
    ONGOING: 'ONGOING',
    REQUIRES_LICENSES: 'REQUIRES_LICENSES',
    REQUIRES_SYNC: 'REQUIRES_SYNC',
    FAILED: 'FAILED',
    SUCCESS: 'SUCCESS',
};

export const STATE_ROUTES = {
    NOT_CONFIGURED: APP_ROUTES.COMPANY_DETAILS,
    ONGOING: APP_ROUTES.CONFIGURE,
    REQUIRES_LICENSES: APP_ROUTES.CONFIGURE_FAILED_LICENSES,
    REQUIRES_SYNC: APP_ROUTES.REQUIRES_SYNC,
    FAILED: APP_ROUTES.CONFIGURE_FAILED,
    SUCCESS: APP_ROUTES.CONFIGURE_SUCCESS,
};

export const STATE_FAILURE = {
    ACCOUNT_NOT_FOUND: 'tenant.route.not.provisioned.or.suspended',
};
