import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Text } from '@fluentui/react-northstar';

import { ReactComponent as NoAccountFound } from 'assets/images/no-account.svg';
import StatusLayout from 'components/Layout/StatusLayout';
import { APP_ROUTES } from 'routes/constants';

import messages from './messages';

const AccountNotFound = () => {
    return (
        <StatusLayout
            image={<NoAccountFound />}
            heading={<FormattedMessage {...messages.accountNotFoundHeading} />}
            content={
                <>
                    <Text align="center" style={{ marginBottom: '10px' }}>
                        <FormattedMessage {...messages.accountNotFoundDescription1} />
                    </Text>
                    <Text align="center" style={{ marginBottom: '10px' }}>
                        <FormattedMessage
                            {...messages.accountNotFoundDescription2}
                            values={{
                                link: (
                                    <Link to={APP_ROUTES.WELCOME}>
                                        <FormattedMessage {...messages.restartProcess} />
                                    </Link>
                                ),
                            }}
                        />
                    </Text>
                </>
            }
        />
    );
};

export default AccountNotFound;
