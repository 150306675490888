import { defineMessages } from 'react-intl';

const messages = defineMessages({
    failedConfigurationLicensesNoPlans1: {
        id: 'features.TenantConfiguration.FailedConfiguration.LicenseNoPlans1',
        defaultMessage: `We can't complete your set-up as you don't have 2 qualifying Microsoft plans available to configure Gamma Voice. `,
    },
    failedConfigurationLicensesNoPlans2: {
        id: 'features.TenantConfiguration.FailedConfiguration.LicenseNoPlans2',
        defaultMessage: `You need to ensure suitable Microsoft plans are available before retrying the set-up. `,
    },
    failedConfigurationLicensesNoAvailableLicenses1: {
        id: 'features.TenantConfiguration.FailedConfiguration.NoAvailableLicense1',
        defaultMessage: `We can't complete your set up as there has been a problem with your Microsoft plan. `,
    },
    failedConfigurationLicensesNoAvailableLicenses2: {
        id: 'features.TenantConfiguration.FailedConfiguration.NoAvailableLicense2',
        defaultMessage: `In the Microsoft admin centre make sure you have:`,
    },
    failedConfigurationLicensesNoAvailableLicensesAccTitle2: {
        id: 'features.TenantConfiguration.FailedConfiguration.NoAvailableLicenseAccTitle2',
        defaultMessage: `2 available Microsoft plans, both with an active subscription`,
    },
    failedConfigurationLicensesNoAvailableLicensesAccMain2: {
        id: 'features.TenantConfiguration.FailedConfiguration.NoAvailableLicenseAccMain2',
        defaultMessage: `Go to Billing > Your products. This will show you a table with the 'Subscription status' of your Microsoft plans`,
    },
    failedConfigurationLicensesNoAvailableLicensesAccTitle3: {
        id: 'features.TenantConfiguration.FailedConfiguration.NoAvailableLicenseAccTitle3',
        defaultMessage: `Teams should be active for both plans. `,
    },
    failedConfigurationLicensesNoAvailableLicensesAccMain3: {
        id: 'features.TenantConfiguration.FailedConfiguration.NoAvailableLicenseAccMain3',
        defaultMessage: `Go to Users > Active users and select any user from the table. Then select 'Licenses and apps' tab and scroll to the bottom to see 'Apps'`,
    },
});

export default messages as typeof messages;
