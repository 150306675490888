import React from 'react';
import { Flex } from '@fluentui/react-northstar';

import { useResponsive } from 'hooks/useResponsive';

const MainLayout = ({
    children,
    width = '550px',
    maxWidth,
    dataTestId = '',
}: {
    children: React.ReactNode;
    width?: string;
    maxWidth?: string;
    dataTestId?: string;
}) => {
    const { isMobile, isTablet, isDesktop, isLargeDesktop } = useResponsive();
    const getStylesByDevice = () => {
        switch (true) {
            case isMobile:
                return {
                    width: 'auto',
                    padding: '10px 15px',
                };
            case isTablet:
                return {
                    width: 'auto',
                    padding: '32px 20px 70px',
                };
            case isLargeDesktop || isDesktop:
                return {
                    padding: '32px auto 70px',
                    width: width,
                };
            default:
                return {
                    padding: '32px auto 70px',
                    width: width,
                };
        }
    };
    const styles = getStylesByDevice();

    return (
        <Flex
            data-testid={dataTestId}
            column={true}
            styles={{
                minWidth: '320px !important',
                overflowX: 'scroll',
                margin: '0 auto',
                maxWidth,
                flexFlow: 'column',
                ...styles,
            }}
        >
            {children}
        </Flex>
    );
};

export default MainLayout;
