import React from 'react';
import { Button, Flex, Text } from '@fluentui/react-northstar';

import MainLayout from './MainLayout';

import { ErrorLayoutProps } from './module/types';

const StatusTableLayout = ({
    image,
    heading,
    width = '450px',
    content,
    action,
    actionText,
    actionStyles,
    actionLoading = false,
    maxWidth = '550px',
    actionDisabled,
}: ErrorLayoutProps) => {
    return (
        <MainLayout width={width} maxWidth={maxWidth}>
            <Flex column={true} hAlign="start">
                {image}
                <Text as="h2" weight="bold" align="center" style={{ marginBottom: '8px' }}>
                    {heading}
                </Text>
                {content}
                <Text as="h2" weight="bold" align="center" style={{ marginBottom: '32px' }} />
            </Flex>
            <Flex column={true} hAlign="end">
                {action && (
                    <Button
                        id="status-table-btn"
                        loading={actionLoading}
                        styles={actionStyles}
                        content={actionText}
                        onClick={action}
                        disabled={actionDisabled}
                        primary
                    />
                )}
            </Flex>
        </MainLayout>
    );
};

export default StatusTableLayout;
